import React, { useState, useEffect, useCallback } from 'react'

import {
	HStack,
	FormControl,
	VStack,
	FormLabel,
	IconButton,
	FormHelperText,
	Icon,
} from '@chakra-ui/react'
import { useAuthContext } from '../../../context/auth.context'
import { HiOutlinePencilAlt, HiCheck, HiX } from 'react-icons/hi'
import { BiHistory } from 'react-icons/bi'
import { BsInfoCircle } from 'react-icons/bs'
import { FormInputField } from '../../../components/form/fieldItem/field.config'
import { toSnakeCase } from '../../../utils/common.util'
import { useSmartFormContext } from '../../../context/smart-form.context'
import { sectionDetailedTrack } from '../../repository/repo.utils'
import { GA_CATEGORY_FORM_MANAGEMENT } from './../../repository/repo.utils'

const FieldEditItem = ({
	field,
	onEdit,
	currentField,
	onEditCancel,
	onFieldSave,
	validateValue,
	isCurrentFieldValid,
	disableEdit,
	activity,
	isFormEnable,
	is_annexure,
	is_edit_config,
	disable_actions,
	isSmartForm,
	onShowHistory = () => {},
	onShowDescription = () => {},
	fieldList = [],
}) => {
	const { field_mandatory_enabled: isRequired, form_id, field_id } = field
	const [isFieldValidate, setIsFieldValidate] = useState(isCurrentFieldValid)
	const [error, setError] = useState(null)
	const [fileUpload, setFileUplaod] = useState(false)
	const [isDelete, setDelete] = useState(false)
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { fields: formFields },
	} = useSmartFormContext()
	const fields = !!formFields[form_id] ? formFields[form_id] : {}

	const onValidate = valid => {
		setIsFieldValidate(valid)
		validateValue(valid)
		if (valid) {
			setError('')
		}
	}

	const onError = err => {
		setIsFieldValidate(false)
		validateValue(false)
		setError(err)
	}

	const isSkipConfig = field => {
		if (field?.field_inline_data?.field_skip_condition) {
			let { data_type_combo_id, field_id } =
				field.field_inline_data.field_skip_condition
			let skipComboId =
				!!fields && !!fields[field_id]
					? fields[field_id]?.field_value?.data_type_combo_id
					: null
			if (data_type_combo_id === skipComboId) {
				return true
			}
			return false
		}
		return [21, 17].includes(field.data_type_id)
	}

	const toDissableField = () => {
		let fValue = !!fields[field_id] && fields[field_id].field_value
		let dis_annex = is_annexure && !!fValue
		let sk = isSkipConfig(field)
		if (isSmartForm) {
			return (
				field?.data_type_id !== 33 &&
				field?.field_value_edit_enabled === 1 &&
				//isFormEnable &&
				!disableEdit &&
				!field?.field_inline_data?.formula?.length &&
				!dis_annex &&
				disable_actions &&
				!sk
			)
		} else {
			return (
				field?.field_value_edit_enabled === 1 &&
				// isFormEnable &&
				!disableEdit &&
				!field?.field_inline_data?.formula?.length &&
				!dis_annex &&
				disable_actions &&
				!sk
			)
		}
	}
	return (
		<HStack
			className={`field-item-edit-container-${toSnakeCase(field.field_name)}`}
			key={field.field_id}
			w='full'
		>
			<FormControl
				className={`field-item-edit-form-${toSnakeCase(field.field_name)}`}
				boxShadow='md'
				borderRadius='md'
				id='field-edit'
				p={2}
				mb={2}
				isRequired={!!isRequired}
			>
				<HStack alignItems='center' justifyContent='space-between' mb={2}>
					{field.data_type_id !== 21 && (
						<VStack>
							<FormLabel
								className={`field-item-form-label-${toSnakeCase(
									field.field_name
								)}`}
								fontWeight='400'
								fontSize='md'
								my={1}
							>
								{field.field_name}
							</FormLabel>
						</VStack>
					)}
					{field.field_id === currentField.field_id && !fileUpload ? (
						<HStack
							className={`field-item-options-${toSnakeCase(field.field_name)}`}
						>
							<IconButton
								className={`field-item-tick-icon-button-${toSnakeCase(
									field.field_name
								)}`}
								bg='white'
								aria-label='close'
								size='sm'
								borderRadius='md'
								boxShadow='md'
								_focus={{
									bg: 'secondary',
								}}
								_hover={{
									bg: 'secondary',
								}}
								isDisabled={
									!!error || (isRequired && isDelete) || !isFieldValidate
								}
								icon={<Icon as={HiCheck} w={4} h={4} />}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_FORM_MANAGEMENT,
										action: 'Field Item Edit',
										label: 'Field Save',
									})
									onFieldSave(field.field_id)
								}}
							/>
							<IconButton
								className={`field-item-cancel-icon-button-${toSnakeCase(
									field.field_name
								)}`}
								bg='white'
								aria-label='close'
								size='sm'
								borderRadius='md'
								boxShadow='md'
								_focus={{
									bg: 'secondary',
								}}
								_hover={{
									bg: 'secondary',
								}}
								icon={<Icon as={HiX} w={4} h={4} />}
								onClick={e => {
									sectionDetailedTrack({
										category: GA_CATEGORY_FORM_MANAGEMENT,
										action: 'Field Item Edit',
										label: 'Cancel Edit',
									})
									onEditCancel(e)
								}}
							/>
						</HStack>
					) : (
						<HStack>
							{toDissableField() && field.data_type_id !== 21 ? (
								<IconButton
									className={`field-item-edit-button-${toSnakeCase(
										field.field_name
									)}`}
									bg='white'
									aria-label='close'
									size='sm'
									borderRadius='md'
									boxShadow='md'
									isDisabled={
										!!Object.keys(currentField).length ||
										(field.form_id === 51493 &&
											activity.activity_status_id === 460812)
									}
									_focus={{
										bg: 'secondary',
									}}
									_hover={{
										bg: 'secondary',
									}}
									icon={<Icon as={HiOutlinePencilAlt} w={4} h={4} />}
									onClick={() => {
										sectionDetailedTrack({
											category: GA_CATEGORY_FORM_MANAGEMENT,
											action: 'Field Item Edit',
											label: 'Edit',
										})
										onEdit(field)
									}}
								/>
							) : null}
							{field.data_type_id !== 21 && (
								<HStack>
									<IconButton
										className={`field-item-history-button-${toSnakeCase(
											field.field_name
										)}`}
										bg='white'
										aria-label='close'
										size='sm'
										borderRadius='md'
										boxShadow='md'
										isDisabled={!!Object.keys(currentField).length}
										_focus={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										icon={<Icon as={BiHistory} w={4} h={4} />}
										onClick={() => {
											sectionDetailedTrack({
												category: GA_CATEGORY_FORM_MANAGEMENT,
												action: 'Field Item Edit',
												label: 'Show History',
											})
											onShowHistory(field)
										}}
									/>
									{!!field.field_description && (
										<IconButton
											className={`field-item-history-button-${toSnakeCase(
												field.field_name
											)}`}
											bg='white'
											aria-label='close'
											size='sm'
											borderRadius='md'
											boxShadow='md'
											isDisabled={!!Object.keys(currentField).length}
											_focus={{
												bg: 'secondary',
											}}
											_hover={{
												bg: 'secondary',
											}}
											icon={<Icon as={BsInfoCircle} w={4} h={4} />}
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_FORM_MANAGEMENT,
													action: 'Field Item Edit',
													label: 'Show Description',
												})
												onShowDescription(field)
											}}
										/>
									)}
								</HStack>
							)}
						</HStack>
					)}
				</HStack>

				<FormInputField
					className={`form-input-field-${toSnakeCase('' + field.field_id)}`}
					isRequired={isRequired}
					field={field}
					isDisabled={field.field_id === currentField.field_id ? false : true}
					onValidate={onValidate}
					onError={onError}
					isFromEdit={true}
					activity={activity}
					isFileUpload={params => setFileUplaod(params)}
					onDelete={params => {
						setDelete(params)
					}}
					fieldList={fieldList}
				/>
				{!!error ? (
					<HStack>
						<FormHelperText color='red.300' fontWeight='400' fontSize='12px'>
							{error}
						</FormHelperText>
					</HStack>
				) : null}
			</FormControl>
		</HStack>
	)
}

export default FieldEditItem
