/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	ModalHeader,
	Text,
	Button,
	useDisclosure,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import FieldEditItem from './fieldItemEdit'
import { useSmartFormContext } from '../../../context/smart-form.context'
import { useSharedContext } from '../../../context/shared.context'
import { sharedConst } from '../../../utils/action.constant'
import { arithmaticCalcu } from '../../../utils/form.util'

import { useFieldEditSave } from '../../../hooks/form.hooks'
import {
	useBulkEditForm,
	useLoadActivityStatusList,
	useLoadActivityTypeList,
} from '../../../hooks/activity.hooks'
import {
	formatDDMMYYYYHHMM,
	getCurrentDateUTC,
} from '../../../utils/date.utils'
import { getMessageUniqueId } from '../../../utils/common.util'
import calcStub from '../../../utils/vil_form_config.util'
import fieldEditConfig from '../../../utils/field_edit_config'
import { loadFinalValue } from '../../../utils/form.util'
import { useUserIdValidation } from '../../../hooks/form.hooks'

const annexureFieldArray = [
	'11182i',
	'11746i',
	'12415i',
	'12949i',
	'12963i',
	'12993i',
	'12891i',
	'10489i',
	'11748i',
	'11029i',
	'26440i',
	'219367i',
	'347187i',
]

const FormEditView = ({
	formData,
	fieldList,
	activity,
	setFieldList,
	isFormEnable,
	onShowHistory = () => {},
	onShowDescription = () => {},
	isSmartForm,
	disable_actions = false,
}) => {
	let boxref = React.createRef()
	const {
		state: { fields: formFields },
	} = useSmartFormContext()
	const fields = !!formFields[formData.form_id]
		? formFields[formData.form_id]
		: {}

	const { dispatch } = useSharedContext()
	const { mutate } = useFieldEditSave()
	const { mutate: mutateActivityTypes } = useLoadActivityTypeList()
	const { mutate: mutateActivityStatus } = useLoadActivityStatusList()
	const [currentField, setcurrentField] = useState({})
	const [isCurrentFieldValid, setisCurrentFieldValid] = useState(false)
	const [activityType, setActivityType] = useState(null)
	const [activityStatus, setActivityStatus] = useState(null)
	const [error, setError] = useState(null)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const { form_transaction_id, form_id, disableEdit, activity_type_id } =
		formData
	const { mutate: mutateBulkEditForm } = useBulkEditForm()
	const { mutate: userIdValidate } = useUserIdValidation()
	const { activity_id, activity_title } = activity

	const onEditField = field => {
		setcurrentField(field)
	}

	useEffect(() => {
		mutateActivityTypes(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityType(data)
				},
			}
		)
		mutateActivityStatus(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityStatus(data)
				},
			}
		)
	}, [formData])

	const scrollToBottom = (f = '', ref = null) => {
		if (!!f) {
			setTimeout(() => {
				if (ref.current) {
					ref.current.scrollIntoView({
						behavior: 'smooth',
					})
				}
			}, 500)
		}
	}

	const prepareBulkEditParams = params => {
		const { data_type_id, field_id } = fieldList[0]
		const {
			activity_id,
			wf_due_date_days,
			form_id,
			form_workflow_activity_type_category_id: activity_type_category_id,
			form_workflow_activity_type_id,
			form_workflow_activity_type_default_duration_days,
		} = formData

		const { activity_type_id } = activityType
		const { activity_status_id } = activityStatus
		let title = `${formatDDMMYYYYHHMM()}-${formData.form_name}`
		if (data_type_id === 65) {
			const parseValue = fields[field_id].field_value
			title = parseValue.contact_name
		} else if (data_type_id === 57) {
			let formTitle = fields[field_id].field_value.label
			title = !!formTitle ? formTitle : title
		} else if (!![19, 20].includes(data_type_id)) {
			title = fields[field_id].field_value
		}

		let formFieldsData = params

		return {
			activity_id: !!activity_id ? activity_id : 0,
			activity_title: title,
			activity_inline_data: JSON.stringify(formFieldsData),
			form_id: form_id,
			activity_form_id: form_id,
			workflow_activity_id: !!activity_id ? activity_id : 0,
			activity_type_id,
			form_workflow_activity_type_id,
			activity_status_id,
			activity_form_id: formData.form_id,
			form_id: formData.form_id,
			form_transaction_id: formData.form_transaction_id,
			workflow_activity_id: formData.activity_id,
			activity_type_category_id: 9,
			message_unique_id: getMessageUniqueId(),
			track_latitude: 0,
			track_longitude: 0,
			track_gps_accuracy: 0,
			track_gps_status: 0,
			track_gps_location: '',
			track_gps_datetime: getCurrentDateUTC(),
			device_os_id: 5,
		}
	}

	const checkForDependentPrefillFields = field_id => {
		const activity_data = []
		fieldList?.forEach(item => {
			if (parseInt(item?.field_inline_data?.dependent_field_id) === field_id) {
				if (item.field_value_prefill_enabled === 1) {
					activity_data.push({
						form_transaction_id,
						form_name: formData.form_name,
						...fields[item.field_id].final,
					})
				}
			}
		})

		return activity_data
	}

	const onFieldSave = async (field_id, isDelete = false) => {
		if (!fields[field_id]) {
			return
		}
		let response = []
		if (
			!!currentField.field_inline_data &&
			currentField.field_inline_data.flag_validation_enabled === 1 &&
			!!currentField.field_inline_data.ethernet_url
		) {
			response = await new Promise(async resolve => {
				return userIdValidate(
					{
						activity_inline_data: JSON.stringify([fields[field_id]?.final]),
						field_id: field_id,
					},
					{
						onSuccess: async data => {
							resolve(data)
						},
						onError: async err => resolve([]),
					}
				)
			})
		}

		if (
			!!response[0] &&
			!['User id available'].includes(response[0]?.message)
		) {
			setisCurrentFieldValid(false)
			setError(response[0]?.message)
			onOpen()
			return
		} else {
			if (Object.keys(calcStub).includes(`${formData.activity_type_id}`)) {
				const processId = formData.activity_type_id
				if (
					!!calcStub[processId] &&
					!!calcStub[processId][field_id] &&
					!!calcStub[processId][field_id].dependentFields
				) {
					const field = calcStub[processId]
					let productValue = 0
					let quantity = 0
					let contractMonths = 0
					let aov = 0
					let conversionRate = 0
					let otc = 0
					const date = new Date()
					let dateOfClosure = date.toISOString().split('T')[0]
					if (!!field.contractMonths) {
						let fieldId = field.contractMonths.field_id
						let fieldData = fields[fieldId]
						if (!!fieldData) {
							contractMonths = fieldData.final.field_value
						}
					}
					if (!!field.productCart) {
						let fieldId = field.productCart.field_id
						let fieldData = fields[fieldId]
						if (!!fieldData && fieldData.final.field_value) {
							let val = JSON.parse(fieldData.final.field_value)?.cart_total_cost
							productValue = !!val ? val : 0
						}
					}
					if (!!field.aov) {
						let fieldId = field.aov.field_id
						let fieldData = fields[fieldId]
						if (!!fieldData) {
							aov = fieldData.final.field_value
						}
					}
					if (!!field.conversionRate) {
						let fieldId = field.conversionRate.field_id
						let fieldData = fields[fieldId]
						if (!!fieldData) {
							conversionRate = fieldData.final.field_value
						}
					}
					if (!!field.dateOfClosure) {
						let fieldId = field.dateOfClosure.field_id
						let fieldData = fields[fieldId]
						if (!!fieldData) {
							dateOfClosure = fieldData.final.field_value
						}
					}
					if (!!field.otc) {
						let fieldId = field.otc.field_id
						let fieldData = fields[fieldId]
						if (!!fieldData) {
							otc = fieldData.final.field_value
						}
					}
					let arc = 0
					if (!!field.annualRecurringCharges) {
						let fieldId = field.annualRecurringCharges.field_id
						let fieldData = fields[fieldId]
						if (!!fieldData) {
							arc = fieldData.final.field_value
						}
					}
					let bulk_params = Object.keys(field[field_id].dependentFields).map(
						fieldId => {
							let fieldIdData = fields[fieldId]
							if (!!field[field_id].bulkCalculateValue)
								var calculatedValue = field[field_id].bulkCalculateValue({
									productValue,
									quantity,
									contractMonths,
									aov,
									conversionRate,
									dateOfClosure,
									otc,
									arc,
								})
							return {
								...fieldIdData.final,
								field_value: calculatedValue[fieldIdData.final.field_id].value,
								form_transaction_id,
								form_name: formData.form_name,
							}
						}
					)
					let params = [
						...bulk_params,
						{
							form_transaction_id,
							form_name: formData.form_name,
							...fields[field_id].final,
						},
					]
					const bulkEditParams = prepareBulkEditParams(params)
					mutateBulkEditForm(
						{
							...bulkEditParams,
						},
						{
							onSuccess: async data => {
								console.log('Bulk edit data', data)
								setcurrentField({})
								setisCurrentFieldValid(false)
								setFieldList()
								dispatch({
									type: sharedConst.REFRESH_TIMELINE,
								})
							},
							onError: async err => {
								console.log('Error', err)
							},
						}
					)
				} else {
					mutate(
						{
							form_transaction_id,
							form_id,
							field_id,
							activity_inline_data: JSON.stringify([
								{
									form_transaction_id,
									form_name: formData.form_name,
									...fields[field_id].final,
								},
							]),
							activity_id: fieldList[0].data
								? fieldList[0].data.final.activity_id
								: formData.activity_id,
							channel_activity_id: activity_id,
							activity_title,
						},
						{
							onSuccess: async data => {
								console.log('single edit data', data)
								setcurrentField({})
								setisCurrentFieldValid(false)
								setFieldList()
								dispatch({
									type: sharedConst.REFRESH_TIMELINE,
								})
							},
						}
					)
				}
			} else {
				let activity_data = [
					{
						form_transaction_id,
						form_name: formData.form_name,
						...fields[field_id].final,
					},
				]
				fieldList.map(val => {
					if (val?.field_inline_data?.formula) {
						if (
							val?.field_inline_data?.formula.find(
								el => Number(el) === field_id
							)
						) {
							activity_data.push({
								form_transaction_id,
								form_name: formData.form_name,
								...loadFinalValue(val, arithmaticCalcu(val, fields, fieldList))
									.final,
							})
						}
					}
				})

				activity_data.push(...checkForDependentPrefillFields(field_id))
				if (activity_data.length > 1) {
					const bulkEditParams = prepareBulkEditParams(activity_data)
					mutateBulkEditForm(
						{
							...bulkEditParams,
						},
						{
							onSuccess: async data => {
								console.log('Bulk edit data', data)
								setcurrentField({})
								setisCurrentFieldValid(false)
								setFieldList()
								dispatch({
									type: sharedConst.REFRESH_TIMELINE,
								})
							},
							onError: async err => {
								console.log('Error', err)
							},
						}
					)
				} else {
					if (isDelete) {
						activity_data[0].field_value = ''
					}

					mutate(
						{
							form_transaction_id,
							form_id,
							field_id,
							activity_inline_data: JSON.stringify(activity_data),
							activity_id: fieldList[0].data
								? fieldList[0].data.final.activity_id
								: formData.activity_id,
							channel_activity_id: activity_id,
							activity_title,
						},
						{
							onSuccess: async data => {
								console.log('single edit data', data)
								setcurrentField({})
								setisCurrentFieldValid(false)
								setFieldList()
								dispatch({
									type: sharedConst.REFRESH_TIMELINE,
								})
								if (activity?.activity_type_category_id === 53) {
									dispatch({
										type: sharedConst.SHOW_LOADER,
									})
									setTimeout(() => {
										dispatch({
											type: sharedConst.HIDE_LOADER,
										})
									}, 9000)
								}
							},
						}
					)
				}
			}
		}
	}

	return (
		<>
			<Box flex={1} w='full' overflowY='scroll' p={2} height='100%'>
				{(fieldList || []).map(field => {
					const { form_id, form_workflow_activity_type_id } = formData
					const is_annexure = annexureFieldArray.includes(field.field_id + 'i')
					const is_edit_config =
						!!fieldEditConfig[form_workflow_activity_type_id] &&
						!!fieldEditConfig[form_workflow_activity_type_id][form_id] &&
						!!fieldEditConfig[form_workflow_activity_type_id][form_id][
							field.field_id
						]
					return (
						<FieldEditItem
							key={field.field_id}
							field={field}
							isFormEnable={isFormEnable}
							onEdit={onEditField}
							currentField={currentField}
							disableEdit={disableEdit}
							onEditCancel={() => {
								setcurrentField({})
								setisCurrentFieldValid(false)
								setFieldList()
							}}
							onFieldSave={onFieldSave}
							isCurrentFieldValid={isCurrentFieldValid}
							validateValue={e => setisCurrentFieldValid(e)}
							activity={activity}
							onShowHistory={onShowHistory}
							onShowDescription={onShowDescription}
							is_annexure={is_annexure}
							is_edit_config={is_edit_config}
							disable_actions={disable_actions}
							isSmartForm={isSmartForm}
							fieldList={fieldList}
							scrollToBottom={e => scrollToBottom(e, boxref)}
						/>
					)
				})}
				<Modal isCentered isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>{'Alert'}</ModalHeader>
						<ModalBody>
							<Text>{error}</Text>
						</ModalBody>
						<ModalFooter>
							<Button
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								mr={3}
								onClick={() => {
									onClose()
								}}
							>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</Box>
			<Box ref={boxref} />
		</>
	)
}

export default FormEditView
